import React from "react"
import Banner from "./Banner"
import Overview from "./Overview"
import Introduction from "./Introduction"
import Approach from "./Approach"
import SliderPage from "./Slider"
import Results from "./Results"
import Services from "./Services"
import Layout from "../../layout"
import SEO from "../../seo"
import Lyringo from "./Lyringo"
import CRM from "./Crm"
import { useSelector } from "react-redux"

const CaseStudyDetails = ({ pageContext }) => {

  const { language } = useSelector(state => state.global)

  const {
    slug,
    casestudy_details: {
      wdwordpress: {
        page: {
          childPages: { nodes },
        },
      },
    },
  } = pageContext

  const data = nodes.filter(item => item.title === slug)

  const {
    casestudy_details: { banner },
  } = data[0]


  const renderPages = () => {
    if (slug === "lyringo") {
      return <Lyringo data={data} />
    } else if (slug === "confident-crm") {
      return <CRM data={data} />
    } else {
      return (
        <>
          <Banner data={data} />
          <Overview data={data} />
          <Introduction data={data} />
          <Approach data={data} />
          <SliderPage data={data} />
          <Results data={data} />
          <Services data={data} />
        </>
      )
    }
  }


  return (
    <Layout>
      <SEO title={banner.title[language]} />
      {renderPages()}
    </Layout>

  )
}

export default CaseStudyDetails
